import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import MoreHelpForm from '../../../components/moreHelpForm/MoreHelpForm';
import GirlWaving from '../../../Assets/Images/girl-waving.png';
import GirlWavingSmall from '../../../Assets/Images/girl-waving-small.png';
import { englishMessages, bahasaMessages } from './MoreHelpMessages';
import './MoreHelp.scss';
import './MoreHelpMobile.scss';

const MoreHelp = ({ isIndoSelected }) => {

  const History = useHistory();

  return (
    <IntlProvider
      messages={isIndoSelected ? bahasaMessages : bahasaMessages}
      locale='en'
      defaultLocale='id'
    >
      {/* Desktop Version */}
        <div className='helppage-more-help-desktop' >
          <div className='helppage-more-help-desktop-inner' >
            <span className='helppage-more-help-desktop-heading' >
              <FormattedMessage id='heading' />
            </span>
            <span className='helppage-more-help-desktop-sub' >
              <FormattedMessage id='subHeading' />
              {/* You can ask anything and we will get <br/> back to you soon! */}
            </span>
            <span className='helppage-more-help-desktop-label' >
              <FormattedMessage id='contactHeading' />
            </span>
            <span className='helppage-more-help-desktop-info' >Email: <a href="mailto:hi@titikpintar.id">hi@titikpintar.id</a></span>
            <span className='helppage-more-help-desktop-label' >
              <FormattedMessage id='officeHeading' />
            </span>
            <span className='helppage-more-help-desktop-info' >
              Jl. Pulau Moyo Perum Jadi Pesona<br/>
              Blk. VII No.8<br />
              Denpasar, Bali 80222 <br />
              Indonesia
            </span>
            <span className='helppage-more-help-desktop-label' >
              Media
            </span>
            <span className='helppage-more-help-desktop-info link' >
              <a
                  onClick={() => History.push('/press')}
              >
              Press Release / Rilis Berita
              </a>
            </span>
            <img src={GirlWaving} alt="Anak perempuan" className='helppage-more-help-desktop-girl' />
            <MoreHelpForm isIndoSelected={isIndoSelected} />
          </div>
        </div>

        {/* Mobile Version */}
        <div className='helppage-more-help-mobile' >
          <span className='helppage-more-help-mobile-heading' >
            <FormattedMessage id='heading' />
          </span>
          <span className='helppage-more-help-mobile-sub'>
            <FormattedMessage id='subHeading' />
          </span>
          <MoreHelpForm isIndoSelected={isIndoSelected} />

          <div className='helppage-more-help-mobile-blue' >
            <span className='helppage-more-help-mobile-label' >
              <FormattedMessage id='contactHeading' />
            </span>
            <span className='helppage-more-help-mobile-info' >
              Email: <a href="mailto:hi@titikpintar.id">hi@titikpintar.id</a>
            </span>
            
            <span className='helppage-more-help-mobile-label office' >
              <FormattedMessage id='officeHeading' />
            </span>
            <span className='helppage-more-help-mobile-info' >
              Jl. Pulau Moyo Perum Jadi Pesona<br />
              Blk. VII No.8<br />
              Denpasar, Bali 80222 <br />
              Indonesia
            </span><br/>
            <span className='helppage-more-help-mobile-label' >
              Media
            </span>
            <span className='helppage-more-help-mobile-info link' >
              <a
                  onClick={() => History.push('/press')}
              >
              Press Release / Rilis Berita
              </a>
            </span>

            <img src={GirlWavingSmall} className='helppage-more-help-mobile-girl' alt='Anak perempuan'  />
          </div>
        </div>
    </IntlProvider>
  );
};

MoreHelp.propTypes = {
  isIndoSelected: PropTypes.bool,
};

export default MoreHelp;
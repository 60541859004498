export const englishMessages = {
  planTab: 'Plan & Payment',
  howToUseTab: 'How To Use',
  accountTab: 'Account',
  heading: 'Hi, how Alby can help you?',
};

export const bahasaMessages = {
  planTab: 'Pembayaran',
  howToUseTab: 'Cara Penggunaan',
  accountTab: 'Tentang Akun',
  heading: 'Hi, apa yang bisa Alby bantu?',
};
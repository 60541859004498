import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, FormattedMessage } from 'react-intl';
import Input from '../input/Input';
import Textarea from '../textarea/Textarea';
import { englishMessages, bahasaMessages } from './MoreHelpFormMessages';
import './MoreHelpForm.scss';

const MoreHelpForm = ({ isIndoSelected }) => {

  return (
    <IntlProvider
      messages={isIndoSelected ? bahasaMessages : bahasaMessages}
      locale='en'
      defaultLocale='id'
    >

      <div className='more-help-form' >
        <form name="contact-page" method="post" action="https://formsubmit.co/hi@titikpintar.id">
          <input type="hidden" name="_captcha" value="false" />
          <input type="hidden" name="form-name" value="contact-page" />
          <FormattedMessage id='namePlaceholder' >
            {namePlaceholder => <Input
              placeholder={namePlaceholder.toString()}
              inputName="name"
              label={<FormattedMessage id='nameLabel' />}
            />}
          </FormattedMessage>

          <div className='more-help-form-input' >
            <FormattedMessage id='emailPlaceholder' >
              {emailPlaceholder =>
                <Input
                  placeholder={emailPlaceholder.toString()}
                  inputName="contact"
                  label={<FormattedMessage id='emailLabel' />}
                />
              }
            </FormattedMessage>
          </div>

          <div className='more-help-form-input' >
            <FormattedMessage id='messagePlaceholder' >
              {messagePlaceholder =>
                <Textarea
                  placeholder={messagePlaceholder.toString()}
                  inputName="remark"
                  label={<FormattedMessage id='messageLabel' />}
                />
            }
            </FormattedMessage>
          </div>

          <div className='more-help-form-bottom' >
            <span className='more-help-form-bottom-text' >
              <FormattedMessage id='formHeading' />
            </span>
            <button className='more-help-form-bottom-btn' >
              <FormattedMessage id='cta' />
            </button>
          </div>
        </form>

      </div>
    </IntlProvider>
  ); 
};

MoreHelpForm.propTypes = {
  isIndoSelected: PropTypes.bool,
};

export default MoreHelpForm;
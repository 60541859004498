import React, { useState } from 'react';
import SmoothCollapse from 'react-smooth-collapse';
import PropTypes from 'prop-types';
import { IntlProvider, FormattedMessage } from 'react-intl';
import IronManAlby from '../../../Assets/Images/iron-man-alby.png';
import Monster from '../../../Assets/Images/monster.png';
import Down from '../../../Assets/Images/down.png';
import { englishMessages, bahasaMessages } from './FaqMessages';
import './Faq.scss';

const howToUseQues = [
  {
    id: 1,
    ques: <FormattedMessage id='howToUseQues1' />,
    ans: <FormattedMessage id='howToUseAns1' />
  },
  {
    id: 2,
    ques: <FormattedMessage id='howToUseQues2' />,
    ans: <FormattedMessage id='howToUseAns2' />
  },
  {
    id: 3,
    ques: <FormattedMessage id='howToUseQues3' />,
    ans: <FormattedMessage id='howToUseAns3' />
  },
  {
    id: 4,
    ques: <FormattedMessage id='howToUseQues4' />,
    ans: <FormattedMessage id='howToUseAns4' />
  },
  {
    id: 5,
    ques: <FormattedMessage id='howToUseQues5' />,
    ans: <FormattedMessage id='howToUseAns5' />
  },
];

const planAndPaymentQues = [
  {
    id: 1,
    ques: <FormattedMessage id='planQues1' />,
    ans: <FormattedMessage id='planAns1' />
  },
  {
    id: 2,
    ques: <FormattedMessage id='planQues2' />,
    ans: (
      <div>
        <div className='faq-point-container' >
          <span className='faq-point' >
            <span className='faq-point-inner' />
          </span>
          <span>
            Telkomsel
          </span>
        </div>
        <div className='faq-point-container' >
          <span className='faq-point' >
            <span className='faq-point-inner' />
          </span>
          <span>
            Virtual Account
          </span>
        </div>
        <div className='faq-point-container' >
          <span className='faq-point' >
            <span className='faq-point-inner' />
          </span>
          <span>
            E-Wallet
          </span>
        </div>
        <div className='faq-point-container' >
          <span className='faq-point' >
            <span className='faq-point-inner' />
          </span>
          <span>
            Credit Card
          </span>
        </div>
        <div className='faq-point-container' >
          <span className='faq-point' >
            <span className='faq-point-inner' />
          </span>
          <span>
            QR Code
          </span>
        </div>
        <div className='faq-point-container' >
          <span className='faq-point' >
            <span className='faq-point-inner' />
          </span>
          <span>
            Minimart
          </span>
        </div>
      </div>
    ),
  },
  {
    id: 3,
    ques: <FormattedMessage id='planQues3' />,
    ans: <FormattedMessage id='planAns3' />
  },
  {
    id: 4,
    ques: <FormattedMessage id='planQues4' />,
    ans: <FormattedMessage id='planAns4' />
  }
];

const accountQues = [
  {
    id: 1,
    ques: <FormattedMessage id='accountQues1' />,
    ans: <FormattedMessage id='accountAns1' />
  },
  // {
  //   id: 2,
  //   ques: <FormattedMessage id='accountQues2' />,
  //   ans: <FormattedMessage id='accountAns2' />
  // },
  {
    id: 3,
    ques: <FormattedMessage id='accountQues3' />,
    ans: <FormattedMessage id='accountAns3' />
  },
  {
    id: 4,
    ques: <FormattedMessage id='accountQues4' />,
    ans: <FormattedMessage id='accountAns4' />
  },

];

const Faq = ({ activeTab, isIndoSelected }) => {

  const [quesOpen, setQuesOpen] = useState(null);

  const toggleQues = (id) => {
    if (quesOpen === id) return setQuesOpen();
    else setQuesOpen(id);
  };

  return (
    <IntlProvider
      messages={isIndoSelected ? bahasaMessages : bahasaMessages}
      locale='en'
      defaultLocale='id'
    >
      <div style={{ position: 'relative' }} >
        <img src={Monster} alt='Monster' className='helppage-faq-monster-mobile' />
        <div className='helppage-faq' >
          <img src={IronManAlby} alt="Iron man alby" className='helppage-faq-iron-man' />
          <img src={Monster} alt='Monster' className='helppage-faq-monster' />


          {/* {activeTab === 'plan' && planAndPaymentQues.map((ques) => (
            <div
              className='helpppage-faq-single'
              onClick={() => toggleQues(ques.id)}
              key={ques.id}
            >
              <span className='helppage-faq-single-ques'>
                {ques.ques}
              </span>
              <img
                src={Down}
                alt="Arah panah bawah"
                className={`helppage-faq-single-down ${quesOpen === ques.id ? 'active' : ''}`}
              />
              <SmoothCollapse expanded={quesOpen === ques.id} heightTransition='.5s ease' >
                <div className='helppage-faq-single-ans' >
                  {ques.ans}
                </div>
              </SmoothCollapse>
            </div>
          ))} */}

          {activeTab === 'howToUse' && howToUseQues.map((ques) => (
            <div
              className='helpppage-faq-single'
              onClick={() => toggleQues(ques.id)}
              key={ques.id}
            >
              <span className='helppage-faq-single-ques'>
                {ques.ques}
              </span>
              <img
                src={Down}
                alt="Arah panah bawah"
                className={`helppage-faq-single-down ${quesOpen === ques.id ? 'active' : ''}`}
              />
              <SmoothCollapse expanded={quesOpen === ques.id} >
                <div className='helppage-faq-single-ans' >
                  {ques.ans}
                </div>
              </SmoothCollapse>
            </div>
          ))}

          {activeTab === 'account' && accountQues.map((ques) => (
            <div
              className='helpppage-faq-single'
              onClick={() => toggleQues(ques.id)}
              key={ques.id}
            >
              <span className='helppage-faq-single-ques'>
                {ques.ques}
              </span>
              <img
                src={Down}
                alt="Arah panah bawah"
                className={`helppage-faq-single-down ${quesOpen === ques.id ? 'active' : ''}`}
              />
              <SmoothCollapse expanded={quesOpen === ques.id} >
                <div className='helppage-faq-single-ans' >
                  {ques.ans}
                </div>
              </SmoothCollapse>
            </div>
          ))}

        </div>
      </div>
    </IntlProvider>
  );
};

Faq.propTypes = {
  activeTab: PropTypes.string,
  isIndoSelected: PropTypes.bool,
};

export default Faq;
import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, FormattedMessage } from 'react-intl';
import SubscribeImg from '../../../Assets/Images/subscribe.png';
import PaperPlane from '../../../Assets/Images/paper-plane.png';
import { englishMessages, bahasaMessages } from './SubscribeMessages';
import './Subscribe.scss';

const Subscribe = ({ isIndoSelected }) => {
  return (
    <IntlProvider
        messages={isIndoSelected ? bahasaMessages : bahasaMessages}
        locale='en'
        defaultLocale='id'
    >
      {/* Desktop Version */}
      <div className="homepage-check-premium">

        <div className="homepage-check-premium-left">
          <span className="helppage-subscribe-heading">
            <FormattedMessage id='heading' />
            {/* Subscribe for <br/> latest info about us */}
          </span>
          <form name="subscribe-desktop" method="post" action="https://formsubmit.co/hi@titikpintar.id">
            <input type="hidden" name="form-name" value="subscribe-desktop" />
            <input type="hidden" name="_captcha" value="false" />
            <div className="helppage-subscribe-email">
              <FormattedMessage id='placeholder' >
                {placeholder =>
                  <input
                    type="text"
                    className="helppage-subscribe-email-input"
                    placeholder={placeholder.toString()}
                    name="subscribe-desktop"
                  />
                }
              </FormattedMessage>
                <button className="helppage-subscribe-email-btn" >
                  <img src={PaperPlane} alt="Tombol berlangganan email"  />
                </button>
            </div>
          </form>
        </div>
        <div className="homepage-check-premium-study-container">
          <img src={SubscribeImg} alt="Anak-anak" className="homepage-check-premium-study" />
        </div>
        <div className="helppage-subscribe-container-base" />
      </div>

      {/* Mobile Version */}
      <div className="homepage-check-premium-mobile">
        <span className="helppage-subscribe-mobile-heading">
          <FormattedMessage id='heading' />
          {/* Subscribe for <br/> latest info about us */}
        </span>
        <form name="subscribe-mobile" method="post" action="https://formsubmit.co/hi@titikpintar.id">
          <input type="hidden" name="_captcha" value="false" />
          <input type="hidden" name="form-name" value="subscribe-mobile" />
          <div className="homepage-check-premium-mobile-img-container">
            <img
              src={SubscribeImg}
              alt="Anak-anak"
              className="homepage-check-premium-mobile-img"
            />
          </div>

          <div className="helppage-subscribe-mobile-email">
              <input
                type="text"
                className="helppage-subscribe-email-input"
                placeholder='Tulis email kamu di sini'
                name="subscribe-mobile"
              />
              <button className="helppage-subscribe-email-btn" >
                <img src={PaperPlane} alt="Icon berlangganan email"  />
              </button>
          </div>
        </form>
      </div>
    </IntlProvider>
  );
};

Subscribe.propTypes = {
  isIndoSelected: PropTypes.bool,
};

export default Subscribe;

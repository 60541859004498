export const englishMessages = {
  heading: 'Need more help?',
  subHeading: 'You can ask anything and we will get back to you soon!',
  contactHeading: 'Contact Info',
  phoneLabel: 'Phone:',
  officeHeading: 'Office',
};

export const bahasaMessages = {
  heading: 'Masih butuh bantuan?',
  subHeading: 'Tanyakan apapun dan kami akan bantu segera!',
  contactHeading: 'Kontak Informasi',
  phoneLabel: 'WhatsApp:',
  officeHeading: 'Kantor',
};
export const englishMessages = {
  planQues1: 'Is Titik Pintar Free?',
  planQues2: 'Which Payment Methods are Available?',
  planQues3: 'Do I Need to Confirm Payment?',
  planQues4: 'Can I Cancel My Payment?',
  planAns1: 'You can play for free for 30 days, after that you need to upgrade the account. You can choose our monthly plan or annual plan starting from Rp 24.900 to 49.000 per month. Please check our Pricing page here.',
  planAns2: 'Something in English',
  planAns3: 'No, you don’t need to confirm payment. You can play instantly after payment.',
  planAns4: 'No, you can’t cancel your payment. But after paying for a month or a year, you can play for an unlimited time.',
  howToUseQues1: 'How To Start Playing Titik Pintar?',
  howToUseQues2: 'Who Can Play Titik Pintar?',
  howToUseQues3: 'What Is Play As Guest?',
  howToUseQues4: 'How Much Should My Kid Be Allowed To Play?',
  howToUseQues5: 'How Many Mini-games Do You Have?',
  howToUseQues6: 'Are There Any Ads In Titik Pintar?',
  howToUseQues7: 'Can I Get Physical Badges?',
  howToUseQues8: 'Can I Buy Merchandise?',
  howToUseAns1: 'Start with downloading Titik Pintar on Google Play or App Store, then register on the app, fill in the registration form and you’re good to go.',
  howToUseAns2: 'Every elementary child in Indonesia can play Titik Pintar!',
  howToUseAns3: 'When you Play As Guest, your data won’t be saved after the 30 days trial ends. So we suggest you register for a full account, so you can keep your progress!',
  howToUseAns4: 'We recommend playing after or before school time for 30 minutes to 1 hour. Titik Pintar will help your children recall lessons from school, so they have a better understanding of the lessons!',
  howToUseAns5: 'Currently, we have 9 Mini-games, but we will add gradually in the future, so stay tuned!',
  howToUseAns6: 'We don’t have ads in our app, so you can enjoy the game freely!',
  howToUseAns7: 'Yes, you can get physical badges on some special occasions. Keep playing and get informed on our social media!',
  howToUseAns8: 'We don’t sell our merchandise for now, but you can get them for free at special events. Follow our social media for more information!',
  accountQues1: 'What Is Titik Pintar?',
  accountQues2: 'How To Upgrade My Account?',
  accountQues3: 'How to Get a Referral Code?',
  accountQues4: 'Do I Have to Input Referral Code When Signing Up?',
  accountQues5: 'What Happens If I Uninstall My App?',
  accountAns1: 'Titik Pintar is a game for elementary school, created with National Curriculum, tailored for children in Indonesia.',
  accountAns2: 'Your 30 days trial ended! Now let’s upgrade to unlock all the games! Complete payment with your desired payment method and you can instantly continue playing.',
  accountAns3: 'You can get a Referral Code from friends, special promotions, giveaways, and many more. For updated information about this, please check our social media.',
  accountAns4: 'If you have a referral code, you can input it in the referral column. But, if you don’t have a referral code, you can skip it.',
  accountAns5: 'Your data will be saved, and whenever you install the Titik App again, make sure you sign in with your old email and password to continue your progress.',
};

export const bahasaMessages = {
  planQues1: 'Apakah Titik Pintar Gratis?',
  planQues2: 'Apa Saja Metode Pembayaran yang Tersedia?',
  planQues3: 'Apakah Saya Perlu Mengkonfirmasi Pembayaran?',
  planQues4: 'Bisakah Saya Membatalkan Pembayaran Saya?',
  planAns1: 'Kamu bisa bermain secara gratis sebanyak 2 topic/hari, setelah itu kamu perlu melakukan upgrade dan membayar dengan periode mingguan, bulanan dan 3 bulanan untuk bisa main semua game!',
  planAns2: 'Something in Bahasa',
  planAns3: 'Tidak, Kamu tidak perlu mengkonfirmasi pembayaran. Kamu dapat bermain sepuasnya setelah pembayaran.',
  planAns4: 'Tidak, Kamu tidak dapat membatalkan pembayaran Kamu. Tetapi setelah membayar selama sebulan atau setahun, Kamu dapat bermain untuk waktu yang tidak terbatas.',
  howToUseQues1: 'Bagaimana Cara Mulai Bermain Titik Pintar?',
  howToUseQues2: 'Siapa yang Bisa Memainkan Titik Pintar?',
  howToUseQues3: 'Apa Itu Bermain Sebagai Tamu?',
  howToUseQues4: 'Berapa Lama Anak Saya Baiknya Bermain?',
  howToUseQues5: 'Berapa Banyak Mini-game yang Ada di Titik Pintar?',
  howToUseQues6: 'Apakah Ada Iklan di Titik Pintar?',
  howToUseQues7: 'Bisakah Saya Mendapatkan Lencana?',
  howToUseQues8: 'Bisakah Saya Membeli Merchandise?',
  howToUseAns1: 'Mulailah dengan mengunduh Titik Pintar di Google Play atau App Store, lalu daftar di aplikasinya, isi formulir pendaftaran dan Kamu sudah bisa bermain.',
  howToUseAns2: 'Setiap anak SD bisa memainkan Titik Pintar!',
  howToUseAns3: 'Saat Kamu Bermain Sebagai Tamu, data Kamu tidak akan disimpan. Jadi kami sarankan Kamu mendaftar sehingga Kamu dapat melanjutkan permainan!',
  howToUseAns4: 'Kami merekomendasikan bermain setelah atau sebelum waktu sekolah selama 30 menit hingga 1 jam. Titik Pintar akan membantu anak-anak mengingat pelajaran di sekolah, sehingga mereka lebih memahami pelajaran!',
  howToUseAns5: 'Saat ini, kami memiliki… Mini-game, tetapi kami akan menambahkan lebih banyak lagi di masa mendatang, jadi pantau terus ya!',
  howToUseAns6: 'Kami tidak memiliki iklan di aplikasi kami, jadi Kamu dapat menikmati permainan dengan bebas!',
  howToUseAns7: 'Ya, Kamu bisa mendapatkan lencana pada beberapa acara khusus. Terus mainkan dan dapatkan informasinya di media sosial kami!',
  howToUseAns8: 'Kami tidak menjual merchandise untuk saat ini, tetapi Kamu bisa mendapatkannya di acara-acara khusus. Ikuti media sosial kami untuk informasi lebih lanjut!',
  accountQues1: 'Apa Itu Titik Pintar?',
  accountQues2: 'Bagaimana Cara Upgrade Akun Saya?',
  accountQues3: 'Bagaimana Cara Mendapatkan Kode Referensi?',
  accountQues4: 'Apa yang Terjadi Jika Saya Menghapus Aplikasi Saya?',
  accountQues5: 'Question in Bahasa missing',
  accountAns1: 'Titik Pintar adalah permainan untuk anak sekolah dasar, dibuat dengan Kurikulum Nasional yang disesuaikan untuk anak-anak di Indonesia.',
  accountAns2: 'Upgrade akun kamu untuk bisa main semua game! Selesaikan pembayaran dengan metode pembayaran yang Kamu inginkan dan Kamu dapat langsung memainkan semua game yang kamu inginkan.',
  accountAns3: 'Kamu bisa mendapatkan Kode Referensi dari teman, promosi khusus, hadiah, dan banyak lagi. Untuk informasi terbaru tentang ini, silakan periksa media sosial kami.',
  accountAns4: 'Data Kamu akan disimpan, dan setiap kali Anda menginstal Aplikasi Titik lagi, pastikan Kamu masuk dengan email dan kata sandi lama untuk melanjutkan permainan.',
  accountAns5: 'Answer in Bahasa missing',
};
import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, FormattedMessage } from 'react-intl';
import Navbar from '../../../components/navbar/Navbar';
import LeftCloud from '../../../Assets/Images/help-header-left-cloud.png';
import RightCloud from '../../../Assets/Images/help-header-right-cloud.png';
import PirateAlby from '../../../Assets/Images/pirate-alby.png';
import { englishMessages, bahasaMessages } from './HelppageHeaderMessages';
import './Header.scss';

const Header = ({ 
  onTabClick,
  activeTab,
  isIndoSelected
  }) => {

  return (
    <IntlProvider
      messages={isIndoSelected ? bahasaMessages : bahasaMessages}
      locale='en'
      defaultLocale='id'
    >

      <div className='helppage-header' >
        <img src={LeftCloud} alt="Awan" className='helppage-header-cloud left' />
        <img src={RightCloud} alt="Awan" className='helppage-header-cloud right' />
        <h1 className='helppage-header-heading'>
          <FormattedMessage id='heading' />
          {/* <span className='tablet-line-break' >Hi,</span>{' '}
          <span className='tablet-line-break' >how Alby</span>{' '}
          <span className='tablet-line-break' >can help you?</span> */}
        </h1>
        <div className='helppage-header-tabs' >
          {/* <div
            className={`helppage-header-tabs-tab ${activeTab === 'plan' ? 'active' : ''}`}
            onClick={() => onTabClick('plan')}
          >
            <FormattedMessage id='planTab' />
            {activeTab === 'plan' && <div className='helppage-header-tabs-tab-triangle' />}
          </div> */}
          <div
          className={`helppage-header-tabs-tab ${activeTab === 'howToUse' ? 'active' : ''}`}
          onClick={() => onTabClick('howToUse')}
          >
            <FormattedMessage id='howToUseTab' />
            {activeTab === 'howToUse' && <div className='helppage-header-tabs-tab-triangle' />}
          </div>
          <div
            className={`helppage-header-tabs-tab ${activeTab === 'account' ? 'active' : ''}`}
            onClick={() => onTabClick('account')}
          >
            <FormattedMessage id='accountTab' />
            {activeTab === 'account' && <div className='helppage-header-tabs-tab-triangle' />}
          </div>
        </div>
        <img src={PirateAlby} alt="Bajak laut alby" className='helppage-header-pirate' />
      </div>
    </IntlProvider>
  );
};

Header.propTypes = {
  onTabClick: PropTypes.func,
  activeTab: PropTypes.string,
  setIsIndoSelected: PropTypes.func,
  isIndoSelected: PropTypes.bool,
};

export default Header;
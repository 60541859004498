import React, { useContext, useState } from "react";
import Layout from "../components/Layout";
import { LangStateContext } from "../context/LangContext";

import Header from '../sections/helpPage/header/Header';
import Faq from '../sections/helpPage/faq/Faq';
import MoreHelp from '../sections/helpPage/moreHelp/MoreHelp';
import Subscribe from '../sections/helpPage/subscribe/Subscribe';
import Seo from '../components/seo/Seo';

const HelpPage = () => {
  const { isIndoSelected } = useContext(LangStateContext);
  console.log(isIndoSelected);

  const [activeTab, setActiveTab] = useState('howToUse');
  const onTabClick = (name) => {
    if (activeTab !== name) setActiveTab(name);
  };

  return (
    <Layout>
      <Seo 
            title='Titik Pintar - Kontak dan Bantuan'
            description='
            Kontak Informasi
            Email: hi@titikpintar.id
            Kantor
            Jl. Pulau Moyo Perum Jadi Pesona
            Blk. VII No.8
            Denpasar, Bali 80222'
            keywords='Kontak Titik Pintar, Bantuan, Titik Pintar Bantuan'
        />
      <div className="homepage" >
      <Header
        onTabClick={onTabClick}
        activeTab={activeTab}
        isIndoSelected={isIndoSelected}
      />
      <Faq activeTab={activeTab} isIndoSelected={isIndoSelected} />
      <MoreHelp isIndoSelected={isIndoSelected} />
      <Subscribe isIndoSelected={isIndoSelected} />
    </div>
    </Layout>
  );
};

export default HelpPage;

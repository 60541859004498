import React from 'react';
import PropTypes from 'prop-types';
import './Textarea.scss';

const Textarea = ({ placeholder, value, onChange, label, rows, inputName }) => {
  return (
    <React.Fragment>
    <label className='input-label' >{label}</label>
    <textarea
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className='textarea'
      rows={rows || '6'}
      name={inputName}
    />
    </React.Fragment>
  );
};

Textarea.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  rows: PropTypes.number,
  inputName: PropTypes.string,
};

export default Textarea;
export const englishMessages = {
  nameLabel: 'NAME',
  emailLabel: 'EMAIL / PHONE NUMBER',
  messageLabel: 'MESSAGE',
  formHeading: "Let's Get Connected",
  cta: 'Send',
  namePlaceholder: 'Enter your name here',
  emailPlaceholder: 'Enter email or phone number here',
  messagePlaceholder: 'Enter message here'
};

export const bahasaMessages = {
  nameLabel: 'NAMA',
  emailLabel: 'EMAIL / NO TELEPON',
  messageLabel: 'PESAN',
  formHeading: 'Yuk Hubungi Kami',
  cta: 'Kirim',
  namePlaceholder: 'Tulis namamu',
  emailPlaceholder: 'Tulis email atau nomor teleponmu',
  messagePlaceholder: 'Tulis pesanmu di sini'
};